import * as bootstrap from 'bootstrap'
import * as Cropper from 'cropperjs'
import 'htmx.org'
import 'cropperjs/src/index.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'

window.bootstrap = bootstrap
window.Cropper = Cropper
